var site = site || {};
var generic = generic || {};
var TencentCaptcha = TencentCaptcha || undefined;

site.translations = site.translations || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };

(function ($) {
  site.captcha = {
    initTCaptcha: function () {
      var $captchaField = $('.js-gnav-captcha');

      if (typeof TencentCaptcha === 'undefined') {
        var body = $('body').get(0);
        var script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = $captchaField.data('jsUrl');
        script.onreadystatechange = site.captcha.scriptLoaded;
        script.onload = site.captcha.scriptLoaded;
        body.appendChild(script);
      } else {
        site.captcha.scriptLoaded();
      }
    },
    scriptLoaded: function () {
      var $captchaField = $('.js-gnav-captcha');
      var $mobileForm = $captchaField.closest('form');
      var bizState = $mobileForm.attr('id') + '_' + parseInt(Math.random() * (9999999999 - 100000000) + 100000000, 10);
      // force convert to string
      var appId = $captchaField.data('appId').toString();
      var $submitBtn = $(':submit', $mobileForm);
      var btnValue = $submitBtn.val();

      if (typeof $.fn.serializeObject === 'undefined') {
        $.fn.serializeObject = function () {
          var o = {};
          var a = this.serializeArray();

          a.forEach(function (v) {
            if (o[v.name]) {
              if (!o[v.name].push) {
                o[v.name] = [o[v.name]];
              }
              o[v.name].push(v.value || '');
            } else {
              o[v.name] = v.value || '';
            }
          });

          return o;
        };
      }

      $mobileForm.once().on('submit', function (event) {
        event.preventDefault();
        var $submitBtn = $(':submit', $(this));

        $submitBtn.val(' ').addClass('loading').prop('disabled', true);
        if (typeof TencentCaptcha !== 'undefined') {
          var tcaptcha = new TencentCaptcha(
            appId,
            function (res) {
              if (res.bizState === bizState && res.ret === 0) {
                var $doc = $(document);

                $captchaField.val(btoa(res.ticket + ' ' + res.randstr));
                $doc.trigger('TCaptcha.success', [res]);
                var formData = $mobileForm.serializeObject();

                generic.jsonrpc.fetch({
                  method: 'rpc.form',
                  params: [formData],
                  onSuccess: function (jsonRpcResponse) {
                    $submitBtn.removeClass('loading').val(btnValue).prop('disabled', false);
                    var resultData = jsonRpcResponse.getData();

                    $doc.trigger('SMS_OTP.success', [resultData]);
                  },
                  onFailure: function (jsonRpcResponse) {
                    $submitBtn.removeClass('loading').val(btnValue).prop('disabled', false);
                    var errorObjectsArray = jsonRpcResponse.getMessages();

                    $doc.trigger('SMS_OTP.failure', [errorObjectsArray]);
                  }
                });
              } else {
                $submitBtn.removeClass('loading').val(btnValue).prop('disabled', false);
              }
            },
            {
              bizState: bizState
            }
          );

          tcaptcha.show();
        }
      });
    }
  };
  Drupal.behaviors.gnavCNAccountV1 = {
    attach: function (context) {
      var $template = $('.js-gnav-account', context);
      var $accountContent = $('.js-gnav-account__content', $template);
      var $blocks = $('.js-gnav-account-content-container', context);
      var $body = $('body');
      var $headerMain = $('.site-header__content', context);
      var $formContainer = $('.gnav-account__content-container-forms', $blocks);
      var $gnavHeaderDarkOverlay = $('<div class="site-gnav-header-dark-overlay"></div>');
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
      var $toggleSigninTrigger = $('.js-gnav-account-content-container-toggle-signin', context);
      var $toggleRegisterTrigger = $('.js-gnav-account-content-container-toggle-register', context);
      var $smsForm = $('.js-gnav-account-content-container-forms-register-sms-form', context);
      var $signInForm = $('.js-gnav-account-content-container-forms-signin-container-form', context);
      var $document = $(document);
      var $smsErrors = $('.js-signin-errors', context);
      var $privacy = $('.js-policy-promotion', context);
      var otpSuccessMessage = '';
      var $errorList = $();

      initSigninForms();
      site.captcha.initTCaptcha();

      $template.once().hover(
        function (e) {
          e.preventDefault();
          if ($accountContent.hasClass('hidden')) {
            if (!isMobile) {
              $accountContent.removeClass('hidden');
              displayGnavHeaderDarkOverlay();
              setOverlayHeight();
            }
          }
        },
        function (e) {
          // Avoid mouseout on select input suggestion
          // Avoid mouseout on hover input field(Firefox)
          if (e.relatedTarget === null || e.relatedTarget.nodeName.toLowerCase() === 'input') {
            return;
          }
          if (!$accountContent.hasClass('hidden')) {
            $accountContent.addClass('hidden');
            removeGnavHeaderDarkOverlay();
          }
        }
      );

      if (parseInt(site.userInfoCookie.getValue('signed_in')) === 0) {
        $blocks.removeClass('is_signed_in');
      } else if (parseInt(site.userInfoCookie.getValue('signed_in')) === 1) {
        $blocks.addClass('is_signed_in');
      }

      $smsForm.once().on('submit', function (event) {
        var error = validateInputsRequired($(this));

        if (error) {
          event.preventDefault();
        }
      });

      $signInForm.once().on('submit', function (event) {
        var error = validateInputsRequired($(this));

        if (error) {
          event.preventDefault();
        }
      });

      $blocks.each(function () {
        var $block = $(this);

        $toggleSigninTrigger.once().on('click', function (event) {
          event.preventDefault();
          $block.removeClass('register-active').addClass('signin-active');
          setOverlayHeight();
        });

        $toggleRegisterTrigger.once().on('click', function (event) {
          event.preventDefault();
          $block.removeClass('signin-active').addClass('register-active');
          setOverlayHeight();
        });
      });

      function validateInputsRequired($element) {
        var error = false;

        if ($element.length === 0) {
          return true;
        }
        $element.find('input').each(function () {
          var $this = $(this);

          if ($this.hasClass('required')) {
            if (!$this.val() || ($this.attr('type') === 'checkbox' && !$this.prop('checked'))) {
              error = true;
              $this.addClass('error');
            } else {
              $this.removeClass('error');
            }
          }
        });

        return error;
      }

      function displayGnavHeaderDarkOverlay() {
        // Add gnav header overlay for DG pages
        if ($('.site-content', $body).length > 0 && !isMobile) {
          $gnavHeaderDarkOverlay.prependTo($('.site-content', $body));
        } else if ($('.pg_wrapper', $body).length > 0 && !isMobile) {
          // Add gnav header overlay for PG pages
          $gnavHeaderDarkOverlay.prependTo($('.pg_wrapper', $body));
        }
        $body.toggleClass('gnav-util-overlay-active');
      }

      function removeGnavHeaderDarkOverlay() {
        $body.toggleClass('gnav-util-overlay-active');
        $gnavHeaderDarkOverlay.remove();
      }

      function setOverlayHeight() {
        var siteHeaderHeight = $headerMain.outerHeight(true);
        var $siteHeaderSticky = $headerMain.find('.gnav-header-block--sticky');
        var accountHeaderHeight = $template.find('.gnav-account__content-container__toggle').outerHeight(true);
        var overlayHeight;
        var formHeight;

        // If sticky nav is active then update the site header height
        if ($siteHeaderSticky.length > 0) {
          siteHeaderHeight = $headerMain.find('.gnav-header-block__inner').outerHeight(true);
        }
        overlayHeight = $(window).height() - siteHeaderHeight;
        formHeight = overlayHeight - accountHeaderHeight;
        // Set height of entire overlay to window height, less gnav offset
        $accountContent.height('auto');
        $accountContent.css('max-height', overlayHeight);

        // Set height and max-height for form content to scroll
        $formContainer.height('auto');
        $formContainer.css('max-height', formHeight);
      }

      Unison.on('change', function (response) {
        isMobile = parseInt(response.width, 10) < parseInt(bps.landscape, 10);
      });

      function initSigninForms() {
        $document.on('SMS_OTP.success', (value, result) => {
          $smsErrors.empty();
          otpSuccessMessage =
            site.translations.elc_general && site.translations.elc_general.confirm
              ? site.translations.elc_general.confirm
              : '';
          $errorList = $('<li class="server"></li>');
          $errorList.html(otpSuccessMessage);
          $smsErrors.append($errorList);
          if (result.is_return_user) {
            $privacy.addClass('hidden');
          } else {
            $privacy.removeClass('hidden');
          }

          return value;
        });

        $document.on('SMS_OTP.failure', (value, errors) => {
          $smsErrors.empty();
          $.each(errors, (item, error) => {
            $errorList = $('<li class="server"></li>');
            $errorList.attr('id', error.key).html(error.text);
            $smsErrors.append($errorList);

            return item;
          });

          return value;
        });
      }
    }
  };
})(jQuery);
